import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Pagination from "../components/shared/Pagination"
import PostPreview from "../components/shared/PostPreview"
import Section from "../components/shared/Section"
import SEO from "../components/shared/SEO"

const CaseStudies = ({ pageContext, data }) => {
  const { currentPage, numPages, basePath } = pageContext

  return (
    <Layout>
      <SEO
        title="Case Studies"
        description="Industry News, Case Studies, and Work Portfolios"
        keywords={[
          `marketing`,
          `digital`,
          `email`,
          `SEO`,
          `search engine optimization`,
          `campaigns`,
          `analytics`,
          `databases`,
        ]}
        pagePath="/case-studies"
        schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Meteor Affinity",
              }
            }`}
      />
      <Section title="Case Studies">
        {data.allContentfulCaseStudy.edges.map(({ node: caseStudy }) => (
          <div key={caseStudy.id}>
            <PostPreview
              title={caseStudy.title}
              logo={caseStudy.companyLogo}
              content={caseStudy.overview.trunc(200, true)}
              url={`/case-studies/${caseStudy.slug}`}
              publishDate={caseStudy.createdAt}
            />
          </div>
        ))}
      </Section>
      <Pagination
        currentPage={currentPage}
        numPages={numPages}
        basePath={basePath}
      ></Pagination>
    </Layout>
  )
}
export default CaseStudies

// HACK HACKITY HACK!: To allow non-required fields in contentful, I've created
// a `dummy-content` page with all the fields filled. This way, Gatsby doesn't
// fail when retrieving empty fields. That's why I filter the content with the
// slug `dummy-content`.
export const pageQuery = graphql`
  query caseStudiesPageQuery($skip: Int!, $limit: Int!) {
    allContentfulCaseStudy(
      sort: { fields: createdAt, order: DESC }
      filter: { slug: { ne: "dummy-content" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          companyLogo {
            fluid(maxWidth: 350, quality: 98) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          overview
          createdAt
        }
      }
    }
  }
`
