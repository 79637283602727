import React from "react"
import styled from "@emotion/styled"
import StyledLink from "../StyledLink"

const Pagination = ({ basePath, currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  if (basePath.slice(-1) !== "/") {
    basePath += "/"
  }
  const prevPage = basePath + (currentPage - 1 === 1 ? "" : currentPage - 1)
  const nextPage = basePath + (currentPage + 1)

  const styleContainer = {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    margin: `2em`,
  }

  const Container = styled.div`
    ${styleContainer}
  `

  return (
    <Container>
      {!isFirst && (
        <StyledLink to={prevPage} rel="prev">
          Previous Page
        </StyledLink>
      )}
      {!isLast && (
        <StyledLink to={nextPage} rel="next">
          Next Page
        </StyledLink>
      )}
    </Container>
  )
}

export default Pagination
